import clsx from 'clsx';
import {
  getButtonColor,
  getButtonDisabledColor,
  getButtonTextColor,
  getHoverBgColor,
  getTextDisabledColor,
} from '~/front/utils/buttonUtil';

export function FormSubmitButton({
  label,
  intentName,
  isLoading,
  type = 'primary',
  fullWidth = false,
  disabled = false,
}: {
  label: string;
  intentName: string;
  isLoading: boolean;
  type?: 'primary' | 'critical' | 'secondary';
  fullWidth?: boolean;
  disabled?: boolean;
}) {
  const defaultBgColor = getButtonColor(type);
  const disabledBgColor = getButtonDisabledColor(type);
  const hoverBgColor = getHoverBgColor(type);
  const textColor = getButtonTextColor(type);
  const disabledTextColor = getTextDisabledColor(type);

  const _isDisabled = isLoading || disabled;
  return (
    <button
      type="submit"
      name="intent"
      value={intentName}
      className={clsx([
        'px-3 py-2 text-xs font-bold min-w-[60px]',
        {
          [disabledBgColor]: _isDisabled,
        },
        {
          [defaultBgColor]: !_isDisabled,
        },
        {
          [hoverBgColor]: !_isDisabled,
        },
        {
          [textColor]: !_isDisabled,
        },
        {
          [disabledTextColor]: _isDisabled,
        },
        {
          'w-full': fullWidth,
        },
      ])}
      disabled={_isDisabled}
    >
      {label}
    </button>
  );
}
